<template>
  <div style="width: 100%;">
    <v-app>
      <v-snackbar
        :timeout="3000"
        :value="true"
        absolute
        top
        color="success"
        right
        text
        v-if="appear"
      >
        {{msg}}
      </v-snackbar>
      <NewCourse :showup="on" @closeModal="cancel($event)" @getResponse="responseData($event)"></NewCourse>
      <v-data-table
        :headers="headers"
        :items="courses"
        :loading="loading"
        sort-by="calories"
        class="elevation-1"
      >
        <template v-slot:item.id="{item}">
          <v-chip small :color="`#9c9c9c`" style="color: white; font-weight: 600; width: 25px; padding: 8px;">
            {{courses.map(function(x) {return x.id; }).indexOf(item.id)+1}}
          </v-chip>
        </template>
        <template v-slot:item.action="{ item }">
          <v-btn small :color="'#40E583'" style="color: #ffffff; margin-top: 10px !important; padding-right: 5px; margin-right: 2px; text-transform: none;" @click="assignClass(item.id)">Assign</v-btn>
        </template>
        <template v-slot:item.title="{ item }">
          <input type="text" style="width: 100%;" :value="item.title" :id="'title'+item.id" @dblclick="editMode(item.id,'title')" @blur="blurMode(item.id,'title')" @keyup.enter="blurMode(item.id,'title')"  readonly/>
        </template>
        <template v-slot:item.code="{ item }">
          <input type="text" :value="item.code" :id="'code'+item.id" @dblclick="editMode(item.id,'code')" @blur="blurMode(item.id,'code')" @keyup.enter="blurMode(item.id,'code')"  readonly/>
        </template>
        <template v-slot:item.credits="{ item }">
          <input type="text" :value="item.credits" :id="'credits'+item.id" @dblclick="editMode(item.id,'credits')" @blur="blurMode(item.id,'credits')" @keyup.enter="blurMode(item.id,'credits')"  readonly/>
        </template>
        <template v-slot:item.points="{ item }">
          <input type="text" :value="item.points" :id="'points'+item.id" @dblclick="editMode(item.id,'points')" @blur="blurMode(item.id,'points')" @keyup.enter="blurMode(item.id,'points')"  readonly/>
        </template>
        <template v-slot:top>
          <v-toolbar
            flat
          >
            <v-toolbar-title>Courses</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="callModal"
              style="text-transform: none;"
            >
              New Course
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mt-2"
            @click="edit(item),state='edit'"
          >
          </v-icon>
        </template>
      </v-data-table>
      <v-dialog
        v-model="AssignModal"
        max-width="500px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Assign Class</span>
          </v-card-title>
          <b-container>
            <v-row>
              <input type="hidden" v-model="id">
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-select
                  v-model="classe"
                  label="Select class"
                  name="Class"
                  :items="classes"
                  v-validate="'required'"
                  outlined
                  dense
                  @click="fetchSelectedClass"
                ></v-select>
                <span style="color: red;"
                  v-show="errors.has('Class')" v-if="valid">{{ errors.first('Class') }}</span>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="year"
                  label="Academic year"
                  name="Academic year"
                  type="text"
                  outlined
                  dense
                  readonly
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-select
                  v-model="semester"
                  label="Choose semester"
                  name="Class"
                  v-validate="'required'"
                  :items="semesters"
                  outlined
                  dense
                ></v-select>
                <span style="color: red;"
                  v-show="errors.has('Class')" v-if="valid">{{ errors.first('Class') }}</span>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-select
                  v-model="lecturer"
                  label="Select lecturer"
                  name="Lecturer"
                  v-validate="'required'"
                  :items="mentors"
                  outlined
                  dense
                ></v-select>
                <span style="color: red;"
                  v-show="errors.has('Lecturer')" v-if="valid">{{ errors.first('Lecturer') }}</span>
              </v-col>
            </v-row>
          </b-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text

              style="text-transform: none;"
              outlined
              @click="quit"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              style="text-transform: none;"
              outlined
              @click="validate"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>
<script>
import {
  mdiAccount,
  mdiPencil,
  mdiShareVariant,
  mdiDelete,
} from '@mdi/js'
import NewCourse from './NewCourse'
export default {
  components: {
    NewCourse,
  },
  data: () => ({
    icons: {
      mdiAccount,
      mdiPencil,
      mdiShareVariant,
      mdiDelete,
    },
    headers: [
      {
        text: '#',
        value: 'id',
      },
      {
        text: 'Title',
        value: 'title',
      },
      {
        text: 'Code',
        value: 'code',
      },
      {
        text: 'Credits',
        value: 'credits',
      },
      {
        text: 'Max points',
        value: 'points',
      },
      {
        text: '',
        value: 'action',
      },
    ],
    date: new Date().toISOString().substr(0, 7),
    modal: false,
    dialogModal: false,
    logger: [],
    header: '',
    courses: [],
    on: false,
    loading: false,
    AssignModal: false,
    classes: [],
    classe: null,
    semesters: [
      {
        text: 'Semester 1',
        value: 1,
      }, {
        text: 'Semester 2',
        value: 2,
      },
    ],
    semester: null,
    mentors: [],
    lecturer: null,
    year: '',
    id: '',
    valid: true,
    appear: false,
    mssg: '',
  }),
  created () {
    this.fetchCourses()
    this.fetchSelectedMentors()
    this.fetchActiveSemester()
  },
  methods: {
    async fetchActiveSemester () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      this.teacher = this.logger.user_name
      try {
        const res = await this.axios({
          url: 'get_active_semester',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.year = res.data.title
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } else if (error.response && error.response.status === 400) {
          this.active_semester = []
        } else {
          console.log(error)
        }
      }
    },
    editMode: function (courseId, key) {
      // alert(courseId + key)
      var course = document.getElementById(key + '' + courseId)
      course.focus()
      course.readOnly = false
      course.classList.add('editMode')
    },
    blurMode (courseId, key) {
      var course = document.getElementById(key + '' + courseId)
      course.readOnly = true
      course.classList.remove('editMode')
      this.editFaculty(courseId, key, course.value)
    },
    async editFaculty (course, key, value) {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      const data = {
        id: course,
        key: key,
        value: value,
      }
      try {
        await this.axios({
          url: 'manipulate_course/0',
          method: 'post',
          data: data,
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
      } catch (e) {
        if (e.response && e.response.status === 403) {
          console.log(e)
        } else {
          console.log(e)
        }
      }
    },
    async fetchCourses () {
      this.loading = true
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_courses',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.loading = false
          this.courses = res.data
          console.log(res.data)
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        }
        if (error.response && error.response.status === 400) {
          this.loading = false
        } else {
          console.log(error)
        }
      }
    },
    callModal () {
      this.on = true
    },
    cancel (event) {
      this.on = event
    },
    responseData (event) {
      this.msg = 'Course created'
      this.appear = true
      this.courses.push(event)
    },
    assignClass (id) {
      this.appear = false
      this.AssignModal = true
      this.id = id
    },
    async fetchSelectedClass () {
      // this.progress = true
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_selected_class',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.progress = false
          this.classes = res.data
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } else if (error.response && error.response.status === 400) {
          this.progress = false
          this.classes = []
        } else {
          console.log(error)
        }
      }
    },
    async fetchSelectedMentors () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_selected_mentors',
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.mentors = res.data
          console.log(res.data)
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } else {
          console.log(error)
        }
      }
    },
    quit () {
      this.semester = ''
      this.lecturer = ''
      this.semester = ''
      this.AssignModal = false
      this.valid = false
    },
    async validate () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.save()
        } else {
          // form have errors
        }
      })
    },
    async save () {
      this.msg = 'Course assigned'
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      const data = {
        classe: this.classe,
        lecturer: this.lecturer,
        year: this.year,
        semester: this.semester,
        course: this.id,
      }
      try {
        const res = await this.axios({
          url: 'manipulate_course_records',
          method: 'post',
          data: data,
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.appear = true
          this.quit()
        } else {
          alert('Data not saved')
        }
      } catch (e) {
        if (e.response && e.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } else if (e.response && e.response.status === 400) {
          alert(e.response.data.messages)
        } else {
          console.log(e)
        }
      }
    },
  },
}
</script>
<style scoped>
  .editMode {
    padding: 3px;
    background-color: #fdf9ff;
    font-size: 14px;
    border: 1px solid;
    border-radius: 3px;
  }
</style>
